import '@splidejs/splide/css';

import Splide from '@splidejs/splide';
document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector("#raum-carousel")) {
    new Splide("#raum-carousel", {
      type: 'loop',
      perPage: 2,
      autoplay: true,
      interval: 5000,
      pagination: false,
      breakpoints: {
        800: {
          perPage: 1,
        }
      },
    }).mount({});
  }
});
